<template>
    <div class="works-view">
        <hero :title="page ? page.title : 'Works'" />

        <section v-if="activeLang === 'en'">
            <carousel
            v-if="items.docs.length > 0"
            :items="items.docs"
            centered>
                <template #item="{ item }">
                    <project-item-slide :item="item" />
                </template>
            </carousel>
        </section>

      <section v-if="activeLang === 'ru'">
          <carousel
          v-if="itemsRu.docs.length > 0"
          :items="itemsRu.docs"
          centered>
              <template #item="{ item }">
                  <project-item-slide :item="item" />
              </template>
          </carousel>
      </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import store from '@/store'

export default {
    name: 'works',
    // async beforeRouteEnter (to, from, next) {
    //     await store.dispatch('getWorks', { category: to.params.slug, limit: -1 })
    //     await store.dispatch('getCategories', { taxonomy: 'work_category' })
    //     next()
    // },
    computed: {
        ...mapState({
            page: state => state.page,
            items: state => state.works,
            itemsRu: state => state.worksRu,
            // categories: state => state.page_categories,
            is_in_transition: state => state.in_transition
        }),
        activeLang() {
          return this.$store.state.language
        }
    }
    // async beforeRouteUpdate (to, from, next) {
    //     next()
    //     await store.dispatch('getWorks', { category: to.params.slug })
    //     await store.dispatch('getCategories', { taxonomy: 'work_category' })
    //     this.$store.commit('SET_APP_IN_TRANSITION', false)
    // }
}
</script>

<style lang="scss" scoped>

.works-link {
    &.router-link-exact-active {
        text-decoration: none;
    }
}

.works {
    min-height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);

    &.works-in-transition {
        .works--carousel {
            filter: blur(3px) grayscale(100%);
        }
    }
}
</style>
