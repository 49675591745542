<template>
    <div class="errors-404-view">
        <section>
            <bxs-layout>
                <div class="pa-ui text-center">
                    <h4 class="mb-3">Ops, error 404</h4>
                    <p>Page <span v-if="$router.redirectedFrom">{{ $router.redirectedFrom.fullPath }}</span> not found</p>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'errors_404'
}
</script>