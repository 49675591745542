<template>
    <div class="article-post-view">
        <hero
        :key="article.img_url"
        :src="article.img_url"
        overlay
        light
        max-width="1368px"
        hide-arrow
        undo="/news"
        undo-label="All news"
        class="post--hero"
        :style="{ height: '55vh' }">
            <h4>{{ article.title }}</h4>
        </hero>

        <!--  -->
        <section id="article-section">
            <bxs-layout
            id="article-layout"
            max-width="1368px">
                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-row>
                    <bxs-col cols="4">
                        <small class="text-mute">{{ article.created_at }}</small>
                    </bxs-col>

                    <bxs-col cols="4">
                        <small>
                            <span
                            v-for="(c, i) in article.categories"
                            :key="i">{{ c.name }}<span v-if="i < article.categories.length - 1">, </span></span>
                        </small>
                        <small></small>
                    </bxs-col>

                    <bxs-col cols="4">
                        <small>Reading</small>
                    </bxs-col>
                </bxs-row>

                <hr>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-row justify="between">
                    <bxs-col
                    cols="12"
                    ml="3">
                        <div>
                            <div class="flex nowrap">
                                <div class="flex-1">
                                    <p class="text-500 small mb-0">{{ article.author.name }}</p>
                                    <p class="text-mute small mb-0">Suisse Consulences</p>
                                </div>

                                <div>
                                    <share-page
                                    v-if="$store.state.is_mobile"
                                    :item="article" />
                                </div>
                            </div>

                            <hr>

                            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                            <div v-if="!$store.state.is_mobile">
                                <share-page
                                :item="article"
                                class="mt-2" />

                                <div
                                v-if="article.tags.length > 0"
                                class="mt-2">
                                    <ul>
                                        <li
                                        v-for="(t, i) in article.tags"
                                        :key="i">
                                            <small>{{ t.name }}</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ml="8">
                        <div
                        v-if="article.content"
                        v-html="article.content"
                        class="wysiwyg"></div>

                        <div
                        v-else
                        class="text-center py-ui">
                            <small>No content available</small>
                        </div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!--  -->
        <highlighted-articles-section
        ref="relateds_section"
        limit="3"
        :category-slug="article.categories[0].slug"
        :exclude="[article.id]" />

        <section>
            <bxs-layout>
                <div class="my-ui py-ui text-center">
                    <small class="text-mute"><router-link to="/">Homepage</router-link> / <router-link to="/news">News</router-link> / {{ article.title }}</small>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SharePage from '@/components/SharePage.vue'

export default {
    name: 'article_post',
    components: {
        'share-page': SharePage
    },
    computed: {
        ...mapState({
            page: state => state.page,
            news: state => state.news,
            article: state => state.article,
            is_in_transition: state => state.in_transition
        })
    }
}
</script>