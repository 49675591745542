<template>
    <section class="section-highlighted-articles">
        <hr>

        <bxs-layout>
            <bxs-row
            align="center"
            class="mb-ui">
                <bxs-col cols="6">
                    <h4 class="mb-0">News</h4>
                </bxs-col>

                <bxs-col
                cols="6"
                class="text-right">
                    <router-link
                    exact
                    to="/news"
                    class="bxs-chip">Read our blog</router-link>
                </bxs-col>
            </bxs-row>

            <bxs-row>
                <bxs-col
                v-for="(item, i) in docs"
                :key="i"
                cols="12"
                ml="6"
                ds="4">
                    <post-row :item="item" />
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'highlighted_articles_section',
    props: {
        'category-slug': {
            type: String,
            required: false,
            default: null
        },
        limit: {
            type: [String, Number],
            required: false,
            default: 3
        },
        exclude: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            docs: []
        }
    },
    mounted () {
        this.getItems()
    },
    methods: {
        getItems () {
            this.$store.dispatch('getNews', {
                category: this.categorySlug,
                exclude: this.exclude,
                limit: this.limit
            }).then((res) => {
                this.docs = res.docs
                this.$nextTick(() => this.$root.resize())
            })
        }
    }
}
</script>