<template>
    <div class="cookie-policy">
        <section class="pt-1">
            <bxs-layout>
                <h1 class="h4">{{ page.title }}</h1>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div
                v-html="page && page.content"
                class="wysiwyg"></div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'cookie_policy',
    computed: {
        ...mapState({
            page: state => state.page
        })
    }
}
</script>