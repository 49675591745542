<template>
    <div :class="['navframe', {
        'navframe-actived': actived,
        'navframe-hidden': hidden,
        'navframe-on': on
    }]">
        <bxs-layout class="navframe--cnt">
            <div>
                <logo ref="logo" />
            </div>

            <div class="lang-selector">
              <div class="lang-list">
                <a href="/">
                  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
                    <path fill="#012169" d="M0 0h640v480H0z"/>
                    <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"/>
                    <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"/>
                    <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z"/>
                    <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z"/>
                </svg>
                </a>
                <a href="/ru">
                  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ru" viewBox="0 0 640 480">
                    <path fill="#fff" d="M0 0h640v160H0z"/>
                    <path fill="#0039a6" d="M0 160h640v160H0z"/>
                    <path fill="#d52b1e" d="M0 320h640v160H0z"/>
                  </svg>
                </a>
              </div>
            </div>

            <div>
                <button
                type="button"
                style="font-size: 21px;"
                @click.prevent="$emit('click:menu')">{{ on ? 'Close' : 'Menu' }}</button>
            </div>
        </bxs-layout>
    </div>
</template>

<script>
export default {
    name: 'navframe',
    props: {
        actived: {
            type: Boolean,
            required: false,
            default: false
        },
        hidden: {
            type: Boolean,
            required: false,
            default: false
        },
        on: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.lang-selector{
  margin-left: auto;
  margin-right: 20px;

  .lang-list{
    transform: translate(0, 2px);

    a{
      margin: 0 12px;

      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        display: block;
        background-color: transparent;
      }

      &:hover:after{
        background-color: #000;
      }
    }

    svg{
      width: 26px;
      height: auto;
      border: 1px solid #666;
    }
  }
}
.navframe {
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: var(--color-background);
    transition: transform 0.25s ease-out;
    overflow: hidden;
    z-index: var(--layer-navframe);

    &.navframe-on {
        background: none;
    }

    &.navframe-actived {
        > .navframe--cnt {
            // border-bottom-color: var(--color-typo);
            &::after {
                background-color: var(--color-typo);
            }
        }
    }

    &.navframe-hidden {
        transform: translateY(-100%);
    }

    &--cnt {
        position: relative;
        min-height: var(--navbar-min-height);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: calc(100% - var(--grid-gutter) * 2);
            height: var(--thickness);
            transition: all 0.25s ease-out;
        }
    }
}
</style>
