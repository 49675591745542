<template>
    <div class="service-card">
        <router-link
        v-if="to"
        :to="$filters.toto(to)">
            <bxs-figure
            :src="src"
            link
            :ratio="ratio">
                <div
                class="overlay overlay-top overlay-bottom"
                style="z-index: 1"></div>

                <div
                class="enderized pa-6"
                style="position: relative; z-index: 2;">
                    <div class="service-card--action py-4 text-white">
                        <p class="h5 mb-0 text-white">{{ title }}</p>
                        <c-arrow />
                    </div>
                </div>
            </bxs-figure>
        </router-link>

        <bxs-figure
        v-else
        :src="src"
        :ratio="ratio">
            <div
            class="overlay overlay-top overlay-bottom"
            style="z-index: 1"></div>

            <div
            class="enderized pa-6"
            style="position: relative; z-index: 2;">
                <div class="service-card--action text-white py-4">
                    <p class="h5 mb-0 text-white">{{ title }}</p>
                </div>
            </div>
        </bxs-figure>
    </div>
</template>

<script>
export default {
    name: 'serviceCard',
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        to: {
            type: String,
            required: false,
            default: null
        },
        src: {
            type: String,
            required: false,
            default: null
        },
        ratio: {
            type: String,
            required: false,
            default: '4/3'
        }
    }
}
</script>

<style lang="scss" scoped>
.service-card {
    position: relative;

    @media (hover: hover) {
        &:hover {
            > a {
                color: white;
                text-decoration: line-through;
            }
        }
    }

    > a {
        display: block;
        text-decoration: none;
    }

    &--action {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid white;
    }
}
</style>
