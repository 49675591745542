import { createStore } from 'vuex'
import Http from '@/assets/libs/Http'
import {
    // mobile as _is_mobile, // is iPhone, iPod, Android Phone, Windows Phone, Blackberry.
    tablet as _is_tablet,
    touchDevice as is_touch_device
} from 'is_js'

const wp = new Http(process.env.VUE_APP_WP_API)
// const static_break_value = 992
const is_mobile = () => window.innerWidth < 768
const is_tablet = () => (window.innerWidth >= 768 && window.innerWidth < 992) || _is_tablet()

// console.log('> store')
// console.log('is_mobile', is_mobile())
// console.log('is_tablet', is_tablet())

export default createStore({
    modules: {
    },
    state: {
        preloaded: false,
        loading: false,
        page_enter: null,
        page_leave: null,
        in_transition: false,
        is_first_enter: true,

        language: 'en',
        //
        is_mobile: is_mobile(),
        is_tablet: is_tablet(),
        is_touch_device: is_touch_device(),
        //
        resources: [
            { type: 'store_fetch', promise: 'getMenu' },
            { type: 'store_fetch', promise: 'getMenuRu' },
            // { type: 'store_fetch', promise: 'getFooter' },
            { type: 'store_fetch', promise: 'getHomeSlider' },
            { type: 'store_fetch', promise: 'getServices' },
            { type: 'store_fetch', promise: 'getServicesRu' },
            { type: 'store_fetch', promise: 'getWorks' },
            { type: 'store_fetch', promise: 'getWorksRu' }
        ],
        //
        menu: [],
        footer: [],
        home_page: null,
        page: null,
        page_categories: [],
        home_slides: [],
        services: null,
        service: null,
        works: null,
        work: null,
        news: null,
        article: null,

        workRu: null,
        worksRu: null,
        serviceRu: null,
        servicesRu: null,
        menuRu: []
    },
    getters: {
      get_language: state => state.language
    },
    mutations: {
        SET_APP_LANG(state, str) {
            state.lang = str
        },
        SET_APP_PRELOADED(state, bool) {
            state.preloaded = bool
        },
        SET_APP_LOADING(state, bool) {
            state.loading = bool
        },
        SET_APP_IN_TRANSITION(state, bool) {
            state.in_transition = bool
        },
        SET_APP_PAGE_ENTER(state, str) {
            state.page_enter = str
        },
        SET_APP_PAGE_LEAVE(state, str) {
            state.page_leave = str
        },
        SET_APP_IS_FIRST_ENTER(state, bool) {
            state.is_first_enter = bool
        },
        resize(state) {
            state.is_mobile = is_mobile()
            state.is_tablet = is_tablet()
            state.is_touch_device = is_touch_device()
            // console.log('store resize: is mobile', state.is_mobile)
        },
        //
        set_language(state, str) {
            state.language = str
        },
        setMenu(state, arr) {
            state.menu = arr
        },
        setFooter(state, arr) {
            state.footer = arr
        },
        setHomePage(state, obj) {
            state.home_page = obj
        },
        setPage(state, obj) {
            state.page = obj
        },
        setPageCategories(state, obj) {
            state.page_categories = obj
        },
        setHomeSlider(state, arr) {
            state.home_slides = arr
        },
        setServices(state, obj) {
            state.services = obj
        },
        setService(state, obj) {
            state.service = obj
        },
        setNews(state, obj) {
            state.news = obj
        },
        setArticle(state, obj) {
            state.article = obj
        },
        setWorks(state, obj) {
            state.works = obj
        },
        setWorksRu(state, obj) {
            state.worksRu = obj
        },
        setWork(state, obj) {
            state.work = obj
        },
        setWorkRu(state, obj) {
            state.workRu = obj
        },
        setServiceRu(state, obj) {
            state.serviceRu = obj
        },
        setServicesRu(state, obj) {
            state.servicesRu = obj
        },
        setMenuRu(state, arr) {
            state.menuRu = arr
        }
    },
    actions: {
        async getMenu({ commit }, id = 2) {
            const data = await wp.getMenu(id)
            commit('setMenu', data)
            return data
        },
        async getFooter({ commit }) {
            const data = await wp.getFooter()
            commit('setFooter', data)
            return data
        },
        async getPage({ commit }, slug) {
            const data = await wp.getPage(slug)
            if (slug === 'home') commit('setHomePage', data.length > 0 ? data[0] : null)
            commit('setPage', data.length > 0 ? data[0] : null)
            return data.length > 0 ? data[0] : null
        },
        async getCategories({ commit }, { taxonomy, parent }) {
            const data = await wp.getCategories(taxonomy, parent)
            commit('setPageCategories', data)
            return data
        },
        async getHomeSlider ({ commit }) {
            const data = await wp.getSlides()
            commit('setHomeSlider', data)
            return data
        },
        //
        async getServices({ commit }, q = {}) {
            const data = await wp.getPosts({ ...q, limit: -1, type: 'service' })
            commit('setServices', data)
            return data
        },
        //
        async getServicesRu({ commit }, q = {}) {
            const data = await wp.getPosts({ ...q, limit: -1, type: 'service', lang: 'ru' })
            commit('setServices', data)
            return data
        },
        async get_service({ commit }, slug) {
            const data = await wp.getPost(slug, 'service')
            commit('setService', data)
            return data
        },
        //
        async getWorks({ commit }, q = {}) {
            const data = await wp.getPosts({ ...q, type: 'work' })
            commit('setWorks', data)
            return data
        },
        async get_work({ commit }, slug) {
            const data = await wp.getPost(slug, 'work')
            commit('setWork', data)
            return data
        },
        //
        async getNews({ commit }, q) {
            const data = await wp.getPosts(q)
            commit('setNews', data)
            return data
        },
        async get_article_post({ commit }, slug) {
            const data = await wp.getPost(slug)
            commit('setArticle', data)
            return data
        },

        // RU,
        async getPageRu({ commit }, slug) {
          const data = await wp.getPageRu(slug)
          if (slug === 'home') commit('setHomePage', data.length > 0 ? data[0] : null)
          commit('setPage', data.length > 0 ? data[0] : null)
          return data.length > 0 ? data[0] : null
        },
        async getWorksRu({ commit }, q = {}) {
            const data = await wp.getPosts({ ...q, type: 'work', lang: 'ru' })
            commit('setWorksRu', data)
            return data
        },
        async get_work_ru({ commit }, slug) {
            const data = await wp.getPost(slug + '?lang=ru', 'work')
            commit('setWorkRu', data)
            return data
        },
        async get_service_ru({ commit }, slug) {
            const data = await wp.getPost(slug + '?lang=ru', 'service')
            commit('setServiceRu', data)
            return data
        },
        async getMenuRu({ commit }, id = 35) {
            const data = await wp.getMenu(id)
            commit('setMenuRu', data)
            return data
        },
        //
        async loadImgs({ commit }, urls) {
            if (urls.length > 1) {
                return urls.reduce((previousPromise, url) => {
                    return new Promise((resolve, reject) => {
                        const image = new Image()
                        image.src = url

                        if (image.complete) {
                            resolve(image)
                        } else {
                            image.onload = () => resolve(image)
                            image.onerror = resolve(image)
                        }
                    })
                }, Promise.resolve())
            }

            const promises = []

            urls.forEach(url => {
                const promise = new Promise((resolve, reject) => {
                    const image = new Image()
                    image.src = url

                    if (image.complete) {
                        resolve(image)
                    } else {
                        image.onload = () => resolve(image)
                        image.onerror = reject
                    }
                })

                promises.push(promise)
            })

            return Promise.all(promises)
        }
    }
})
