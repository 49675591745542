<template>
    <section class="tertiary text-white py-16" v-if="home_page">
        <bxs-layout>
            <bxs-row justify="center">
                <bxs-col
                cols="12"
                ds="8"
                dl="6">
                    <div class="mb-ui">
                        <p class="mb-0">{{ home_page && home_page.mission ? home_page.mission.title : '' }}</p>
                        <hr>
                    </div>

                    <p class="h4" style="line-height: 1.25;">{{ home_page && home_page.mission ? home_page.mission.paragraph : '' }}</p>

                    <p class="mb-0 text-disabled">
                        <span>CEO</span>
                        <span class="mx-2">-</span>
                        <a
                        :href="home_page && home_page.mission ? home_page.mission.to_url : '#'"
                        target="_blank">
                            <span>Emanuele Della Torre</span>
                            <c-arrow dir="external" class="ml-3" />
                        </a>
                    </p>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'mission_section',
     computed: {
        ...mapState({
            home_page: state => state.home_page
        })
    }
}
</script>
