<template>
    <div :class="['application', {
        'app-loading': in_transition || !preloaded,
        'app-no-evt': in_transition
    }]">
        <preloader
        v-if="!preloaded"
        ref="preloader"
        :resources="resources"
        @complete="preloadComplete" />

        <div
        v-else
        ref="wrapper"
        id="wrapper"
        class="application--wrapper"
        data-scroll-container>
            <!-- navframe -->
            <!-- data-scroll-sticky
            data-scroll-target="#wrapper" -->
            <navframe
            ref="navframe"
            :actived="navframe.actived || menu.on"
            :on="menu.on"
            class="application--navframe"
            @click:menu="menu.on = !menu.on" />

            <!-- view -->
            <div
            ref="view"
            class="application--view">
                <main
                ref="main"
                class="application--main">
                    <router-view
                    ref="view"
                    v-slot="{ Component }">
                        <transition
                        @enter="enterPage"
                        @leave="leavePage"
                        mode="out-in"
                        appear>
                            <component
                            v-if="preloaded"
                            :is="Component"
                            :key="$route.fullPath"
                            ref="page"
                            :class="['application--page', {
                                'page-is-in-transition': in_transition
                            }]" />
                        </transition>
                    </router-view>
                </main>

                <!-- foot -->
                <c-footer
                ref="footer"
                data-scroll-section
                class="application--footer" />
            </div>

            <!-- big menu -->
            <transition
            mode="out-in"
            :css="false"
            @enter="enterBigMenu"
            @leave="leaveBigMenu">
                <big-menu
                v-if="menu.on"
                ref="menu" />
            </transition>

            <!-- transition layer -->
            <!-- <div
            ref="layer"
            class="app-transition-layer">
                <div>loading ...</div>
            </div> -->

            <!--  -->
            <Teleport to="body">
                <coookies
                buttonText="Accept"
                storageName="https://suisseconsulences.ch:cookie:accepted">
                    <template #default="{ accept }">
                        <div class="flex-1 text-ui">
                            <div class="mb-8">
                                <small class="text-600">Cookie Policy</small>
                            </div>

                            <div class="mb-ui">
                                <p>This website does not use profiling cookies. Instead, technical cookies, including third-party cookies, are used in order to improve the browsing experience. <br> By clicking on the "Accept" button, you will close the banner without giving consent to any cookies except those that are necessary.</p>
                            </div>

                            <div class="text-right">
                                <c-btn
                                pill
                                min-width="80"
                                style="margin-right: 0.5rem;"
                                @click="accept">Accept</c-btn>

                                <!-- <c-btn
                                pill
                                min-width="80"
                                class="mr-4"
                                @click="accept">Decline</c-btn> -->

                                <bxs-menu title="Cookie settings">
                                    <template #activator="{ on }">
                                        <c-btn
                                        v-on="on"
                                        pill
                                        outlined
                                        min-width="80"
                                        @click="cookie.on = true">Settings</c-btn>
                                    </template>

                                    <div>
                                        <div class="pa-4">
                                            <logo max-width="80" />
                                        </div>

                                        <div class="bxs-divider"></div>

                                        <div class="pa-4">
                                            <p class="text-ui text-500 text-mute h6 mb-2">Strictly necessary cookies</p>
                                            <p class="mb-4">Strictly necessary cookies should always be turned on in order to save your preferences for cookie settings.</p>

                                            <div class="b-switch">
                                                <div>
                                                    <input type="checkbox" id="switch2" disabled :value="true" checked />
                                                    <label for="switch2">Disabled</label>
                                                </div>
                                                <span>Actived</span>
                                            </div>
                                        </div>

                                        <div class="bxs-divider"></div>

                                        <div class="pa-4">
                                            <p class="text-ui text-500 h6 text-mute mb-2">Third-party cookies</p>
                                            <p class="mb-4">Google Maps is a map display service operated by Google that allows this site to show the location of your business.</p>

                                            <div class="b-switch">
                                                <div>
                                                    <input type="checkbox" id="switch" disabled />
                                                    <label for="switch" disabled>Disabled</label>
                                                </div>
                                                <span>Disabled</span>
                                            </div>
                                        </div>

                                        <div class="bxs-divider"></div>
                                    </div>

                                    <template #actions>
                                        <div class="px-4 text-right">
                                            <c-btn
                                            pill
                                            light
                                            min-width="80"
                                            @click="accept">Save</c-btn>
                                        </div>
                                    </template>
                                </bxs-menu>
                            </div>
                        </div>
                    </template>
                </coookies>
            </Teleport>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils'
// import FontFaceObserver from 'fontfaceobserver'
import transitions from '@/assets/libs/transitions'
import LocoScroll from 'locomotive-scroll'

import Cookie from '@/components/Cookie.vue'
import Preloader from '@/components/Preloader.vue'
import Navframe from '@/components/layout/Navframe.vue'
import FooterComp from '@/components/layout/Footer.vue'
import BigMenu from '@/components/layout/BigMenu.vue'

export default {
    name: 'App',
    components: {
        preloader: Preloader,
        navframe: Navframe,
        'c-footer': FooterComp,
        'big-menu': BigMenu,
        coookies: Cookie
    },
    computed: {
        ...mapState({
            resources: state => state.resources,
            preloaded: state => state.preloaded,
            loading: state => state.loading,
            in_transition: state => state.in_transition,
            is_router_ready: state => state.is_router_ready,
            page: state => state.page
        }),
        activeLang() {
          return this.$store.state.language
        }
    },
    data () {
        return {
            scroll: null,
            navframe: {
                hidden: false,
                actived: true,
                height: 0
            },
            window: {
                height: 0
            },
            menu: {
                on: false
            }
        }
    },
    created () {
        this.$router.beforeEach((to, from, next) => {
            console.log('router int beforeEach()')

            if (this.menu.on) {
                this.menu.on = false
                console.log('close big menu')
                console.log('\n')
                return this.$eventHub.$on('big-menu-trasition-complete', next)
            }

            next()
        })
    },
    head () {
        const has_dynamic_seo = !!this.page && !!this.page.seo
        // const has_robots = has_dynamic_seo && !!this.page.seo.data.robots
        const has_og_img = has_dynamic_seo && this.page.seo.data && this.page.seo.data.og_image && this.page.seo.data.og_image.length > 0

        return {
            title: has_dynamic_seo ? this.page.seo.data.title : '',
            meta: [
                { name: 'description', content: has_dynamic_seo ? this.page.seo.data.description : '' },
                { name: 'article:modified_time', content: has_dynamic_seo ? this.page.seo.data.article_modified_time : '' },
                // OG
                { name: 'og:type', content: has_dynamic_seo ? this.page.seo.data.og_type : '' },
                { name: 'og:site_name', content: has_dynamic_seo ? this.page.seo.data.title : '' },
                { name: 'og:title', content: has_dynamic_seo ? this.page.seo.data.og_description : '' },
                { name: 'og:description', content: has_dynamic_seo ? this.page.seo.data.title : '' },
                { name: 'og:image', content: has_og_img ? this.page.seo.data.og_image[0].url : '' },
                { name: 'og:url', content: window.location.href },
                // TWC
                { name: 'twitter:card', content: has_dynamic_seo ? this.page.seo.data.twitter_card : '' },
                { name: 'twitter:title', content: has_dynamic_seo ? this.page.seo.data.og_description : '' },
                { name: 'twitter:description', content: has_dynamic_seo ? this.page.seo.data.title : '' },
                { name: 'twitter:image', content: has_og_img ? this.page.seo.data.og_image[0].url : '' },
                // indexing
                { name: 'robots', content: has_dynamic_seo ? `${this.page.seo.data.robots.index},${this.page.seo.data.robots.follow}` : '' }
            ],
            script: [
                { type: 'application/ld+json', content: has_dynamic_seo ? JSON.stringify(this.page.seo.data.schema) : '' }
            ]
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        in_transition (newVal) {
            if (newVal === true) {
                this.pause()
            } else {
                this.play()
            }
        },
        'menu.on' (newVal) {
            if (newVal === true) {
                this.pause()
            } else {
                this.play()
            }
        }
    },
    methods: {
        /**
         * ---------------------------------------------------------------------------------------------------------
         * players
         * ---------------------------------------------------------------------------------------------------------
         */
        async start () {
            console.log('> App start()')

            this.resize()
            window.addEventListener('resize', this.resize)

            console.log('preloading ...')
            this.$refs.preloader.start()

           try {
                await this.getPage()
            } catch (err) {
                this.$router.push({ name: 'error_404' })
            }

            // const mm = window.matchMedia('screen and (max-width: 575px)')
            // mm.addListener(e => {})

            // this.ratioHandleMatchMedia(this.ratio_local_matches.mm, 'mm')

            // this.ratio_local_matches.ml = window.matchMedia('screen and (min-width: 576px) and (max-width: 991px)')
            // this.ratio_local_matches.ml.addListener(e => this.ratioHandleMatchMedia(e, 'ml'))
            // this.ratioHandleMatchMedia(this.ratio_local_matches.ml, 'ml')

            // this.ratio_local_matches.ds = window.matchMedia('screen and (min-width: 992px)')
            // this.ratio_local_matches.ds.addListener(e => this.ratioHandleMatchMedia(e, 'ds'))
            // this.ratioHandleMatchMedia(this.ratio_local_matches.ds, 'ds')
        },
        preloadComplete () {
            console.log('preload complete ✓')
            this.$store.commit('SET_APP_PRELOADED', true)
            this.play()
        },
        play () {
            this.$nextTick(() => {
                console.log('play()', this.$route)

                if (!this.scroll) {
                    this.scroll = new LocoScroll({
                        el: this.$el.querySelector('[data-scroll-container]'),
                        smooth: true,
                        initPosition: { x: 0, y: 0 },
                        direction: 'vertical',
                        gestureDirection: 'vertical',
                        lerp: 0.1,
                        getDirection: true,
                        getSpeed: false,
                        // multiplier: 2,
                        // firefoxMultiplier: 25,
                        touchMultiplier: 2,
                        scrollFromAnywhere: false,
                        tablet: { direction: 'vertical', smooth: true },
                        smartphone: { direction: 'vertical', smooth: true }
                    })
                }

                this.resize()

                if (this.scroll) {
                    this.scroll.update() // Updates all element positions
                    this.scroll.start() // Restarts the scroll events
                    this.scroll.on('scroll', this.scrollHandler)
                }

                // const blanks = [...document.querySelectorAll(['a[target="_blank"]'])]
                // blanks.forEach((el) => {
                //     if (!el.hasAttribute('rel')) {
                //         el.setAttribute('rel', 'noopener')
                //     }
                // })
            })
        },
        pause () {
            console.log('app pause()')

            if (this.scroll) {
                // Stops the scroll events
                this.scroll.stop()
            }
        },
        async getPage () {
            const pgs = ['work', 'service', 'article_post']
            console.log('getPage() ...', this.$store.state.page_enter)

            if (this.$route.meta.is_page && this.$store.state.page_enter !== this.$store.state.page_leave) {
                if (this.activeLang === 'ru') {
                  const oldPath = this.$route.path
                  const newPath = oldPath.replace(/^\/ru/, '')
                  const page = await this.$store.dispatch('getPageRu', newPath === '/ru/' ? 'home' : newPath)
                  if (!page) throw new Error('route.not_found')
                } else {
                  const page = await this.$store.dispatch('getPage', this.$route.path === '/' ? 'home' : this.$route.path)
                  if (!page) throw new Error('route.not_found')
                }
            }

            if (pgs.includes(this.$route.name)) {
              if (this.activeLang === 'ru') {
                console.log('ru')
                const subpage = await this.$store.dispatch(`get_${this.$route.name}_ru`, this.$route.params.slug)
                if (!subpage) throw new Error('route.not_found')
              } else {
                const subpage = await this.$store.dispatch(`get_${this.$route.name}`, this.$route.params.slug)
                if (!subpage) throw new Error('route.not_found')
              }
            }

            return true
        },
        /**
         * ---------------------------------------------------------------------------------------------------------
         * handlers
         * ---------------------------------------------------------------------------------------------------------
         */
        scrollHandler (evt) {
            // console.log('scrollHandler', evt)
            // this.navframe.hidden = evt.scroll.y >= evt.limit.y - window.innerHeight
            this.navframe.actived = evt.scroll.y <= evt.limit.y - this.navframe.height
        },
        resize () {
            console.log('resize()')

            if (this.$refs.navframe) this.navframe.height = this.$refs.navframe.$el.clientHeight
            this.window.height = window.innerHeight
            fixVh()
            this.$store.commit('resize')
            if (this.scroll) this.scroll.update()
        },
        /**
         * ---------------------------------------------------------------------------------------------------------
         * trasitions hooks
         * ---------------------------------------------------------------------------------------------------------
         */
        async enterPage (el, done) {
            try {
                console.log('trans hook enterPage()')

                // scroll-sections are optional but recommended to improve performance, particularly in long pages.
                const sections = [...document.querySelector('.application--page').querySelectorAll('section')]
                console.log('sections count', sections.length)
                if (sections.length > 0) {
                    sections.forEach((e) => {
                        if (!e.hasAttribute('data-scroll-section')) {
                            e.setAttribute('data-scroll-section', '')
                        }
                    })
                }

                if (this.scroll) {
                    this.scroll.scrollTo(0, { duration: 0, disableLerp: true })
                }

                await transitions.get('fallback')(this.$refs.wrapper, 'in')

                this.$store.commit('SET_APP_IN_TRANSITION', false)
                done()
            } catch (err) {
                console.log('enterPage err', err)
                this.$router.push({ name: 'error_404' })
            }
        },
        async leavePage (el, done) {
            console.log('trans hook leavePage()')
            if (this.scroll) {
                this.scroll.scrollTo(0, { duration: 1500 })
            }

            // const actions = [...document.querySelectorAll(['a button'])]
            // actions.forEach((el) => {
            //     el.disabled = true
            // })

            await transitions.get('fallback')(this.$refs.wrapper, 'out')

            try {
                await this.getPage(done)
            } catch (err) {
                done()
                return this.$router.push({ name: 'error_404' })
            }

            done()
        },
        enterBigMenu (el, next) {
            return transitions.get('big_menu')(el, 'in').then(() => {
                next()
            })
        },
        leaveBigMenu (el, next) {
            return transitions.get('big_menu')(el, 'out').then(() => {
                this.$eventHub.$emit('big-menu-trasition-complete')
                next()
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>
