import { gsap } from 'gsap'

const transitions = new Map()

// ------------------------------------------------------------------------------------------------------
const fallback = (el, direction, next) => {
    const isIn = direction === 'in'
    // const layer = el.querySelector('.app-transition-layer')
    // const layer_el = el.querySelector('.app-transition-layer > div')
    // const page = el.querySelector('.application--main')
    // const footer = el.querySelector('.application--footer')
    const view = el.querySelector('.application--view')

    const tl = gsap.timeline({
        onComplete: next
    })

    tl
    .addLabel('start')
    // .fromTo(layer, {
    //     scaleX: isIn ? 1 : 0,
    //     transformOrigin: isIn ? 'top left' : 'top right'
    // }, {
    //     scaleX: isIn ? 0 : 1,
    //     transformOrigin: isIn ? 'top right' : 'top left',
    //     duration: 2,
    //     ease: 'expo.inOut'
    //     // clearProps: isIn
    // })
    // .fromTo(layer_el, {
    //     autoAlpha: isIn ? 0 : 1
    // }, {
    //     autoAlpha: isIn ? 1 : 0,
    //     duration: 0.5,
    //     ease: 'expo.inOut'
    // }, 'start+0.25')
    .fromTo(view, {
        autoAlpha: isIn ? 0 : 1,
        y: isIn ? 20 : 0
        // scale: isIn ? 1.25 : 1
    }, {
        autoAlpha: isIn ? 1 : 0,
        y: isIn ? 0 : 20,
        // scale: isIn ? 1 : 1.25,
        duration: isIn ? 1.5 : 1,
        ease: isIn ? 'expo.out' : 'expo.inOut'
    })
}

const bigMenu = (el, direction, next) => {
    const isIn = direction === 'in'
    const items = [...el.querySelectorAll('[data-big-menu-item]')]

    const tl = gsap.timeline({
        onComplete: next
    })

    tl
        .addLabel('start')

    if (isIn) {
        tl
            .fromTo(el, {
                autoAlpha: 0
            }, {
                autoAlpha: 1,
                duration: 0.5,
                ease: 'expo.out'
            })
            .fromTo(items, {
                y: '200%'
            }, {
                y: 0,
                stagger: 0.05,
                ease: 'expo.out'
            })
    } else {
        tl
            .fromTo(el, {
                autoAlpha: 1
            }, {
                autoAlpha: 0,
                duration: 0.5,
                ease: 'expo.out'
            })
    }
}

const layer = (el, direction, next) => {
    const isIn = direction === 'in'

    const tl = gsap.timeline({
        onComplete: next
    })

    tl.addLabel('start').fromTo(el, {
        x: isIn ? 0 : 1
    }, {
        x: isIn ? 1 : 0,
        ease: 'expo.inOut',
        duration: 1
        // clearProps: isIn
    })
}

// ------------------------------------------------------------------------------------------------------

[{
    key: 'fallback',
    transition: fallback
}, {
    key: 'big_menu',
    transition: bigMenu
}, {
    key: 'layer',
    transition: layer
}].forEach((item) => {
    transitions.set(
        item.key,
        (ctx, direction) => new Promise(resolve => item.transition(ctx, direction, resolve))
    )
})

export default transitions