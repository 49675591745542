<template>
    <div :class="['sc-carousel', {
        'sc-carousel-items-per-view-1': parseInt(itemsPerView) <= 1,
        'sc-carousel-no-hoverable': noHoverable
    }]">
        <swiper
        :speed="speed"
        :direction="direction"
        :modules="modules"
        :slides-per-view="itemsPerView"
        :space-between="itemsSpaceBetween"
        :centered-slides="centered"
        :loop="loop"
        :pagination="pagination"
        :scrollbar="scrollbar"
        :navigation="navigation"
        :freeMode="freemode"
        :mousewheel="mousewheel"
        grab-cursor>
            <swiper-slide
            v-for="(item, i) in items"
            :key="i"
            :class="{
                actived: hovered_i === i,
                deactived: hovered_i !== null && hovered_i !== i
            }"
            :style="{
                width: itemWidth,
                maxWidth: itemWidth
            }"
            @mouseenter="hover(i)"
            @mouseleave="hover(null)">
                <slot name="item" :item="item" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import {
    Pagination,
    Navigation,
    Scrollbar,
    Mousewheel,
    FreeMode
} from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

export default {
    name: 'sc_carousel',
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        'items-per-view': {
            type: [String, Number],
            required: false,
            default: 'auto'
        },
        'items-space-between': {
            type: [String, Number],
            required: false,
            default: 0
        },
        'item-width': {
            type: String,
            required: false,
            default: '100%'
        },
        pagination: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        navigation: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        scrollbar: {
            type: [Boolean, Object],
            required: false,
            default: () => {
                return {
                    hide: false,
                    enabled: true,
                    draggable: true
                }
            }
        },
        freemode: {
            type: [Boolean, Object],
            required: false,
            default: () => {
                return {
                    enabled: false,
                    sticky: false
                }
            }
        },
        mousewheel: {
            type: [Boolean, Object],
            required: false,
            default: () => {
                return {
                    // Imposta su true per forzare i movimenti della rotellina del mouse sull'asse. Quindi in modalità orizzontale la rotellina del mouse funzionerà solo con lo scorrimento orizzontale della rotellina del mouse e solo con lo scorrimento verticale in modalità verticale.
                    forceToAxis: true,
                    invert: false,
                    // Impostato su true e lo swiper rilascerà l'evento della rotellina del mouse e consentirà lo scorrimento della pagina quando lo swiper si trova sulle posizioni del bordo (all'inizio o alla fine)
                    releaseOnEdges: false,
                    sensitivity: 0.8
                }
            }
        },
        direction: {
            type: String,
            required: false,
            default: 'horizontal'
        },
        speed: {
            type: Number,
            required: false,
            default: 500
        },
        centered: {
            type: Boolean,
            required: false,
            default: false
        },
        loop: {
            type: Boolean,
            required: false,
            default: false
        },
        'grab-cursor': {
            type: Boolean,
            required: false,
            default: true
        },
        'no-hoverable': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            modules: [
                Pagination,
                Navigation,
                Scrollbar,
                Mousewheel,
                FreeMode
            ],
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                // when window width is >= 1023
                960: {
                    slidesPerView: this.itemsPerView,
                    spaceBetween: this.itemsSpaceBetween
                }
            },
            hovered_i: null
        }
    },
    methods: {
        hover (i) {
            if (this.noHoverable) return
            this.hovered_i = i
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/mq.scss';

.sc-carousel {
    &.sc-carousel-items-per-view-1 {
        .swiper-slide {
            padding: 0 !important;
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}

.swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
        padding: 0 var(--grid-gutter);
        width: 100% !important;
        max-width: 100% !important;

        @include mq(ml) {
            width: 80vw !important;
            max-width: 80vw !important;
            padding: 0 4vw;
        }

        @include mq(ds) {
            width: 50vw !important;
            max-width: 50vw !important;
            padding: 0 4.5vw;
        }

        // @include mq(dl) {
        //     width: 40vw !important;
        //     max-width: 40vw !important;
        // }

        transition: filter 0.5s ease-in-out;

        &.deactived {
            filter: grayscale(100%); /* Standard */
                -webkit-filter: grayscale(100%); /* Webkit */
                filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale"); /* Firefox4-34*/
                filter: gray;  /* IE6-9 */
                -webkit-filter: grayscale(1); /* Old WebKit */
           pointer-events: none;
        }
    }

    .swiper-scrollbar {
        position: relative !important;
        display: block;
        height: 10px;
        left: 0;
        width: calc(20% - 2rem);
        margin: 0 auto;
        margin-top: 2rem;
        background-color: rgba(#000, 0.15);
        border-radius: 0;
        transform: scaleY(0.1);
        transform-origin: left center;
        transition: transform 0.25s ease-out;

        @media (hover: hover) {
            &:hover {
                transform: scaleY(1);
                cursor: grab;
            }
        }

        > div {
            background-color: black;
            border-radius: 0;
        }
    }
}
</style>