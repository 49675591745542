<template>
    <div class="form-comp">
        <form
        class="form-comp--form"
        ref="form"
        role="form"
        :autocomplete="autocomplete"
        aria-label="Form name"
        novalidate="novalidate"
        @submit.prevent="submit"
        @input="handleInput">
            <slot />
        </form>
    </div>
</template>

<script>
function parseDotNotation (str, val, obj) {
    let currentObj = obj
    const keys = str.split('.')
    let i
    const l = Math.max(1, keys.length - 1)
    let key

    for (i = 0; i < l; ++i) {
      key = keys[i]
      currentObj[key] = currentObj[key] || {}
      currentObj = currentObj[key]
    }

    currentObj[keys[i]] = val
    delete obj[str]
}

Object.expand = (obj) => {
  for (const key in obj) {
    if (key.indexOf('.') !== -1) parseDotNotation(key, obj[key], obj)
  }
  return obj
}

export default {
  name: 'bxs_form',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    'hide-footer': {
      type: Boolean,
      required: false,
      default: false
    },
    'submit-text': {
      type: String,
      required: false,
      default: 'salva'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    autocomplete: {
      type: [String, Boolean],
      required: false,
      default: 'off'
    }
  },
  computed: {
    inputs () {
      return this.$slots.default().filter((i) => {
          return i.type.name && i.type.name.includes('field')
      }).map(i => i.type)
    }
  },
  data () {
    return {
      valid: false,
      submitable: false,
      oldValues: [],
      newValues: []
    }
  },
    mounted () {
        this.$nextTick(() => {
            this.oldValues = this.inputs.map(item => item.data().cacheValue)
        })
    },
  methods: {
    handleInput (evt) {
      this.newValues = []
      this.inputs.forEach(item => this.newValues.push(item.data().cacheValue))
      this.submitable = this.newValues.join(',') !== this.oldValues.join(',')
      this.$emit('input', { submitable: this.submitable })
    },
    submit () {
      this.valid = this.checkValidate()
      const data = {}

      if (this.valid) {
        this.inputs.forEach(child => {
          if (!child.$options.name.includes('field')) return
          data[child.name] = child.value
          // console.log(data[child.name], child.value)
        })

        // console.log(Object.expand(data))
        return this.$emit('submit', Object.expand(data))
      }
    },
    checkValidate () {
      let status = true

      this.inputs.forEach((child) => {
        if (!child.$options.name.includes('field')) return
        const check = child.validate()
        if (!check) status = false
      })

      return status
    },
    reset () {
      this.inputs.forEach((field) => {
        if (field && field.reset && typeof field.reset === 'function') field.reset()
      })
    }
  },
  beforeUnmount () {
    this.submitable = false
    this.valid = false
    this.oldValues = []
    this.newValues = []
  }
}
</script>

<style lang="scss" scoped>
.form-comp {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 auto;
}
</style>
