// comps
import Logo from './Logo.vue'
import FigureComp from './FigureComp.vue'
import Layout from './Layout.vue'
import Row from './Row/Row.vue'
import Col from './Col/Col.vue'
import Btn from './Btn.vue'
import Arrow from './Arrow.vue'
import Card from './Card.vue'
import BuroLinks from './BuroLinks.vue'
import PostRow from './project/PostRow.vue'
import HighlightedArticles from './sections/HighlightedArticles.vue'
import BxsSwitchField from './Form/BxsSwitchField.vue'
import Menu from './Menu.vue'

import Carousel from './CCarousel.vue'
import ProjectItemSlide from './ProjectItemSlide.vue'
import ItemSlide from './ItemSlide.vue'

//
import Hero from './layout/Hero.vue'

import Services from './sections/Services.vue'
import Works from './sections/Works.vue'
import Contacts from './sections/Contacts.vue'
import Mission from './sections/Mission.vue'

export default function createComp(app) {
    app.component('logo', Logo)
    app.component('bxs-figure', FigureComp)
    app.component('bxs-layout', Layout)
    app.component('bxs-row', Row)
    app.component('bxs-col', Col)
    app.component('c-btn', Btn)
    app.component('c-arrow', Arrow)
    app.component('card', Card)
    app.component('buro-links', BuroLinks)
    app.component('post-row', PostRow)
    app.component('bxs-switch-field', BxsSwitchField)
    app.component('bxs-menu', Menu)

    app.component('services-section', Services)
    app.component('works-section', Works)
    app.component('contacts-section', Contacts)
    app.component('mission-section', Mission)
    app.component('highlighted-articles-section', HighlightedArticles)

    app.component('hero', Hero)
    app.component('carousel', Carousel)
    app.component('project-item-slide', ProjectItemSlide)
    app.component('item-slide', ItemSlide)
}