<template>
    <section
    data-scroll-section
    :class="['hero', { light: light }]">
        <div
        v-if="src"
        class="hero--src"
        :style="{ backgroundImage: `url(${src})` }"></div>

        <div
        v-if="overlay"
        class="hero--overlay"></div>

        <bxs-layout
        :max-width="maxWidth"
        class="hero--layout">
            <div class="flex nowrap align-end justify-between">
                <div class="flex-1">
                    <router-link
                    v-if="undo"
                    :to="undo"
                    class="flex align-center pointer mb-2">
                        <c-arrow
                        dir="left"
                        class="mr-2"></c-arrow>

                        <span>{{ undoLabel }}</span>
                    </router-link>

                    <h1
                    v-if="title"
                    class="h2 mb-0 pb-0">{{ title }}</h1>

                    <slot />
                </div>

                <div
                v-if="!hideArrow"
                class="h2 pointer"
                @click="scrollTo">
                    <c-arrow dir="left" />
                </div>
            </div>

            <!-- <div
            v-if="!hideScrollto"
            class="flex nowrap align-center pointer"
            @click="scrollTo">
                <small class="text-mute">{{ scrollLabel }}</small>

                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="0.75rem"
                height="0.75rem"
                fill="rgb(130, 130, 130)"
                class="icon ml-2"
                viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                </svg>
            </div> -->
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'hero',
    props: {
        undo: String,
        'undo-label': {
            type: String,
            required: false,
            default: 'Undo'
        },
        title: String,
        src: String,
        overlay: Boolean,
        light: Boolean,
        'max-width': {
            type: String,
            required: false,
            default: null // '1280px'
        },
        //
        align: {
            type: String,
            required: false,
            default: 'end'
        },
        scrollto: String,
        'hide-scrollto': Boolean,
        'hide-arrow': Boolean,
        'scroll-label': {
            type: String,
            required: false,
            default: 'scroll to discover'
        }
    },
    methods: {
        scrollTo () {
            this.$root.scroll.scrollTo(this.scrollto || window.innerHeight, { duration: 500 })
        }
    }
}
</script>

<style lang="scss" scoped>
.hero {
    position: relative;
    margin: 0;
    padding: 0;

    padding-top: 1rem;
    padding-bottom: 1rem;

    &--src {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: none;
        z-index: 1;
    }

    &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
        z-index: 2;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, rgba(#000, 0.4), rgba(#AC0401, 0.1));
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &--layout {
        position: relative;
        height: 100%;
        z-index: 3;
        padding-bottom: var(--size-ui);
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        // h1 {
        //     font-size: 12.5vw;
        // }
    }
}
</style>