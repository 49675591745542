<template>
    <div class="service-view">
        <hero
        :title="service.title"
        :hide-arrow="$store.state.is_mobile" />

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section class="pb-0">
            <bxs-figure
            :key="service.img_url"
            :src="service.img_url"
            ratio="21/9" />
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout max-width="1368px">
                <div
                v-html="service.content"
                class="wysiwyg"></div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <contacts-section  />

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <works-section />

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <h3>More services</h3>
                        <div
                        v-if="data_page"
                        v-html="data_page.content"
                        class="wysiwyg"></div>
                    </bxs-col>
                </bxs-row>

                <bxs-row>
                    <bxs-col
                    v-for="service in services.docs"
                    :key="service.id"
                    cols="12"
                    ds="4">
                        <card
                        :title="service.title"
                        :src="service.img_url"
                        :to="service.permalink" />
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'service',
    data () {
        return {
            data_page: null
        }
    },
    computed: {
        ...mapState({
            page: state => state.page,
            service(state) {
              const langService = {
                  en: state.service,
                  ru: state.serviceRu
              }
              return langService[this.activeLang] || state.servicee
            },
            services(state) {
              const langServices = {
                  en: state.services,
                  ru: state.servicesRu
              }
              return langServices[this.activeLang] || state.services
            }
        }),
        activeLang() {
          return this.$store.state.language
        }
    },
    created () {
      if (this.activeLang === 'ru') {
        this.$wp.getPageRu('services', 'ru').then((doc) => {
              this.data_page = doc[0]
              this.$nextTick(() => this.$root.resize())
          })
      } else {
        this.$wp.getPage('services').then((doc) => {
            this.data_page = doc[0]
            this.$nextTick(() => this.$root.resize())
        })
      }
    }
}
</script>
