<template>
    <div class="index-view">
        <hero scrollto="#section-works">
          <h1 v-if="activeLang === 'en'" :class="[$store.state.is_mobile ? 'h3' : 'h2', 'text-ui', 'mb-0']">We deal with project <br> engineering consultancy</h1>
          <h1 v-if="activeLang === 'ru'" :class="[$store.state.is_mobile ? 'h3' : 'h2', 'text-ui', 'mb-0']">RU - We deal with project <br> engineering consultancy</h1>
        </hero>

        <section
        v-if="slides.length > 0"
        class="pt-0">
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="11"
                    dl="10">
                        <home-carousel :items="slides" />
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <works-section />

        <mission-section />

        <services-section />

        <!--
        <highlighted-articles-section />
        -->
    </div>
</template>

<script>
import { mapState } from 'vuex'
import HomeCarousel from '@/components/HomeCarousel2.vue'

export default {
    name: 'index',
    components: {
        'home-carousel': HomeCarousel
    },
    computed: {
        ...mapState({
            works: state => state.works,
            slides(state) {
              // return state.home_slides
              return state.home_slides.filter(item => item.language.language_code === this.activeLang)
            }
            // news: state => state.news
        }),
        activeLang() {
          return this.$store.state.language
        }
    },
    methods: {
        scrollTo (id) {
            this.$root.scroll.scrollTo(id, { duration: 1000 })
        }
    }
}
</script>
