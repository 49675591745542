import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import createComps from './components'

// libs
import Http from './assets/libs/Http'
import Eventer from './assets/libs/Eventer'
import filters from './filters'
import directives from './directives'
import { createHead, VueHeadMixin } from '@unhead/vue'

if (process.env.NODE_ENV === 'production') {
    console.log = function () {}
}

const app = createApp(App)
const head = createHead()

app.config.globalProperties.$env = process.env.NODE_ENV
app.config.globalProperties.$wp = new Http(process.env.VUE_APP_WP_API)
app.config.globalProperties.$eventHub = new Eventer()
app.config.globalProperties.$filters = filters

// comps
directives(app)
createComps(app)
app.mixin(VueHeadMixin)

// app.config.errorHandler = (err, vm, info) => {
//     console.log('error handler', err, vm, info)

//     if (err.code && err.code === 'rest_no_route') {
//         if (err.data && err.data.status && err.data.status === 404) {
//             vm.$router.push(404)
//         }
//     }
// }

app.use(head).use(store).use(router)

router.isReady().then(() => {
    app.mount('#app', true)
})
