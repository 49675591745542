<template>
    <div
    :class="classes"
    :style="styles">
        <div
        class="text-field--content"
        @click="handleClick">
            <div
            v-if="$slots.prepend"
            class="text-field--content--prepend-content"
            @click="$emit('click:prepend')">
                <slot name="prepend" />
            </div>

            <div class="text-field--content--input-content">
                <label
                ref="label"
                :for="name">{{ required ? '* ' + label : label }}</label>

                <input
                ref="input"
                v-model="cacheValue"
                :type="internalType"
                :name="name"
                :id="name"
                :aria-label="ariaLabel"
                :placeholder="placeholder"
                :readonly="readonly"
                :disabled="disabled"
                :autofocus="autofocus"
                :autocomplete="autocomplete"
                :minlength="minLength"
                :maxlength="maxLength"
                :required="required"
                @focus="handleFocus(true)"
                @blur="handleFocus(false)"
                @input="handleModelInput"
                @change="$emit('change', cacheValue)">
            </div>

            <div
            v-if="$slots.append"
            class="text-field--content--append-content"
            @click="$emit('click:append')">
                <slot name="append" />
            </div>
        </div>

        <div
        v-if="hint"
        class="text-field--hint">
            <div>
              <span>{{ hint }}</span>
            </div>

            <div>
                <span v-if="minLength">{{ minLength }}</span>

                <span v-if="minLength && maxLength" class="mx-1">-</span>

                <span v-if="maxLength">{{ charsCount }}</span>
                <span v-if="maxLength" style="margin: 0 1px;">/</span>
                <span v-if="maxLength">{{ maxLength }}</span>
            </div>
        </div>

        <div
        v-if="!valid && cacheErrors.length > 0"
        class="text-field--error">
            <ul>
                <li
                v-for="(error, i) in cacheErrors"
                :key="i">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'bxs_text_field',
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    autocomplete: {
      type: [String, Boolean],
      required: false,
      default: 'off'
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    'aria-label': {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: 'name-' + Math.random().toString(36).slice(2)
    },
    hint: {
      type: String,
      required: false,
      default: null
    },
    'min-length': {
      type: [String, Number],
      required: false,
      default: null
    },
    'max-length': {
      type: [String, Number],
      required: false,
      default: null
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    'max-counter': {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: [
    'update:modelValue',
    'change'
  ],
  computed: {
    classes () {
      return {
        field: true,
        'text-field': true,
        'text-field-readonly': this.readonly,
        'text-field-raised': Boolean(this.focus || this.placeholder || this.cacheValue),
        'text-field-focused': Boolean(this.focus),
        'text-field-error': Boolean(!this.valid),
        'text-field-disabled': this.disabled,
        disabled: this.disabled
      }
    },
    styles () {
      return {}
    },
    isPassword () {
      return this.type === 'password' ||
                    this.name === 'password' ||
                    this.name === 'oldPassword' ||
                    this.name === 'newPassword'
    }
  },
  data () {
    return {
      cacheValue: this.modelValue,
      charsCount: 0,
      focus: false,
      valid: true,
      cacheErrors: this.errors,
      showPass: false,
      internalType: this.type
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.autofocus) this.validate()
    })
  },
  watch: {
    modelValue (newValue) {
      this.copyValue(newValue)
    },
    cacheValue () {
      this.charsCount = this.cacheValue ? this.cacheValue.length : 0
    },
    showPass (newVal) {
      if (newVal) this.internalType = this.type
      else this.internalType = 'password'
    },
    valid (newVal) {
      if (newVal === false) {
        this.$refs.label.classList.add('error-anim')

        setTimeout(() => {
          this.$refs.label.classList.remove('error-anim')
        }, 1000)
      }
    }
  },
  methods: {
    handleClick () {
      this.$refs.input.focus()
    },
    handleModelInput (evt) {
      this.validate()
      this.updateValue()
    },
    updateValue (val) {
      if (this.valid) {
        this.$emit('update:modelValue', this.cacheValue)
      }
    },
    copyValue (value) {
      this.cacheValue = value
      this.validate()
    },
    handleFocus (focused) {
      this.focus = focused
    },
    reset () {
      this.cacheValue = null
      this.cacheErrors = []
      this.valid = true
      this.$emit('update:modelValue', this.cacheValue)
    },
    validate () {
      let v = true

      if (!this.required) {
        v = true
      } else {
        v = this.$el ? this.$refs.input.validity.valid : this.valid
      }

      this.cacheErrors = []

      if (this.rules.length > 0) {
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            const resRule = rule(this.cacheValue)
            console.log('resRule', resRule)

            if (typeof resRule === 'string') {
              this.cacheErrors.push(resRule)
            } else if (typeof resRule === 'boolean') {
              v = resRule
            } else {
              console.error('error valid input =>', resRule)
            }
          }
        })

        v = this.cacheErrors.length === 0
      }

      // console.log('validate', v)

      this.valid = v
    //   this.cacheErrors = []

      return {
        value: v,
        errors: this.cacheErrors
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* ---------------------------------------------
    text-field
--------------------------------------------- */

$space: calc(0.15rem * var(--input-size, 3));

$input-height: calc(1rem * var(--input-size, 3));

$font-size-base: calc($input-height / 3);
$font-size-small: calc($input-height / 4);
$font-size-smallest: calc($font-size-small / 1.1);
$font-weight: normal;

$label-transition: 0.2s ease all;
$label-top: calc(50% - ($font-size-base / 2));
$label-top-raised: $space;
$input-padding-top: calc($label-top-raised * 2);

$hint-margin-top: $label-top-raised;
$error-margin-top: $label-top-raised;

$background-color: var(--color-tertiary);
$color-disabled: var(--color-disabled);
$color-input: var(--color-white);
$color-label: var(--color-disabled);
$color-placeholder: $color-disabled;
$color-hint: $color-disabled;
$color-focused: var(--color-primary);
$color-error: var(--color-error);

// Mixins:
@mixin label-slided-top() {
    // position: relative;
    top: $label-top-raised;
    font-size: $font-size-small;
}

.text-field {
    position: relative;
    user-select: none;
    margin-bottom: 1rem;
    border: none;

    * {
        box-sizing: border-box;
    }

    &.outlined {
        > .text-field--content {
            background: none;
            border-color: var(--color-secondary);

            > .text-field--content--prepend-content,
            > .text-field--content--append-content {
                border-color: var(--color-secondary);
            }
        }
    }

    &.text-field-readonly {
    }

    &.text-field-disabled {
        > .text-field--content {
            color: $color-disabled !important;

            > .text-field--content--input-content {
                > input,
                > label {
                    color: $color-disabled !important;
                }
            }
        }

        &.outlined {
            > .text-field--content {
                border-style: dashed;
            }
        }
    }

    &.text-field-focused {
        > .text-field--content {
            border-color: $color-focused;

            > .text-field--content--input-content {
                > label {
                    color: $color-focused !important;
                }
            }
        }
    }

    &.text-field-raised {
        > .text-field--content {
            // border-color: $color-focused;

            > .text-field--content--input-content {
                > label {
                    @include label-slided-top();
                    color: $color-input;
                }
            }
        }
    }

    &.text-field-error {
        > .text-field--content {
            border-color: $color-error !important;

            > .text-field--content--input-content {
                > label {
                    color: $color-error !important;
                }
            }
        }
    }

    &--content {
        position: relative;
        display: flex;
        flex-flow: row nowrap;

        height: $input-height;

        font-family: var(--typo-family-ui);
        font-weight: var(--action-typo-weight);
        line-height: 100%;

        border-width: var(--thickness);
        border-style: solid;
        border-color: transparent;
        border-radius: var(--radius);
        background-color: $background-color;

        white-space: nowrap;

        > div {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            border: none;
            padding: 0 $input-padding-top;
        }

        &--prepend-content {
            border-right-width: var(--thickness);
            border-right-style: solid;
            border-right-color: transparent;
        }

        &--append-content {
            border-left-width: var(--thickness);
            border-left-style: solid;
            border-left-color: transparent;
        }

        &--input-content {
            flex: 1;
            position: relative;
            cursor: text;
            align-items: flex-start !important;
            justify-content: flex-start !important;

            > input {
                display: block;
                width: 100%;
                height: 100%;
                font-size: $font-size-base;
                background: none;
                border: none;
                outline: none;
                padding-top: $input-padding-top;
                color: $color-input;

                &:focus {
                    outline: none;
                    border: none;
                }

                &::placeholder {
                    font-size: $font-size-base;
                    color: $color-placeholder;
                }
            }

            > label {
                display: block;
                position: absolute;
                top: $label-top;
                color: $color-label;
                font-size: $font-size-base;
                transition: $label-transition;
                margin-bottom: $space;
                white-space: nowrap;
                line-height: 100%;
            }
        }
    }

    &--hint {
        position: relative;
        font-family: var(--typo-text-family);
        font-weight: normal;
        font-size: $font-size-smallest;
        font-weight: $font-weight;
        line-height: 100%;
        color: $color-hint;
        pointer-events: none;
        margin: $hint-margin-top;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        > div {
            &:first-child {
                flex: 1;
                line-height: 100%;
            }

            &:last-child {
                margin-left: 1rem;
                text-align: right;
            }
        }
    }

    &--error {
        position: relative;
        overflow: hidden;
        margin-top: $error-margin-top;
        color: $color-error;
        font-size: $font-size-smallest;

        > ul,
        li {
            display: block;
            line-height: 100%;
            overflow: hidden;
            margin-top: 0;
            padding: 0;
        }
    }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.error-anim {
    animation-name: shake;
    animation-duration: 0.5s;
}
</style>