<template>
    <section
    class="primary text-white pointer"
    style="padding-top: 10vh; padding-bottom: 10vh;"
    @click="$router.push('/contacts')">
        <bxs-layout class="text-center">
            <p class="h4 mb-0">
                <span class="mr-2">Need help with a project?</span>
                <a role="button">Get in touch.</a>
            </p>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'c-contacts'
}
</script>

<style lang="scss" scoped>
section {
    @media (hover: hover) {
        &:hover {
            a {
                text-decoration: line-through;
            }
        }
    }
}
</style>