<template>
    <div
    :class="['arrow', `arrow-${dir}`]"
    :style="{ maxWidth: size }"
    @click="$emit('click')">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="45 0 113 110">
            <g
            fill="none"
            stroke="currentColor"
            :stroke-width="strokeWidth">
                <path d="M52.676 20.352l.001 84.352 84.353.001"></path>
                <path d="M52.676 104.704L152.43 4.95"></path>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'arrow',
    props: {
        dir: {
            type: String,
            required: false,
            default: 'right'
        },
        'stroke-width': {
            type: String,
            required: false,
            default: '13'
        },
        size: {
            type: String,
            required: false,
            default: '0.85em'
        }
    }
}
</script>

<style lang="scss" scoped>
.arrow {
    position: relative;
    display: inline-block;
    width: 100%;
    // border: 1px solid blue !important;

    &.arrow-right {
        > svg {
            transform: rotate(-90deg);
        }
    }

    &.arrow-external {
        > svg {
            transform: rotate(-180deg);
        }
    }

    > svg {
        width: 100%;
        height: 100%;
        // border: 1px solid green !important;
    }
}
</style>