<template>
    <div class="news-view">
        <hero
        id="hero"
        :title="page ? page.title : 'News'"
        scrollto="#posts" />

        <section v-if="page && page.content">
            <bxs-layout>
                <div
                v-html="page && page.content"
                class="wysiwyg"></div>
            </bxs-layout>
        </section>

        <section id="posts">
            <bxs-layout max-width="1280px">
                <div>
                    <!-- breadcrumbs -->
                    <div class="mb-4">
                        <router-link
                        v-if="!!news.category"
                        exact
                        :to="news.category.undo_to"
                        class="h6 flex align-center pointer"
                        style="text-decoration: none;">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1rem"
                            height="1rem"
                            fill="#000"
                            viewBox="0 0 16 16"
                            class="mr-2">
                                <!-- <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> -->
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                            <span>{{ news.category.name }}</span>
                        </router-link>

                        <h6
                        v-else
                        class="text-400 mb-0">All topics</h6>
                    </div>

                    <!-- chips -->
                    <div>
                        <router-link
                        exact
                        active-class="bxs-chip-active"
                        to="/news"
                        class="mr-2 mb-2 bxs-chip">All topics</router-link>

                        <span v-if="categories && categories.length > 0">
                            <router-link
                            v-for="cat in categories"
                            :key="cat.id"
                            exact
                            active-class="bxs-chip-active"
                            :to="`/news/${cat.slug}`"
                            class="mr-2 mb-2 bxs-chip">{{ cat.name }}</router-link>
                        </span>
                    </div>
                </div>

                <!--  -->
                <bxs-row
                v-if="news.docs.length > 0"
                class="posts">
                    <bxs-col
                    v-for="post in news.docs"
                    :key="post.id"
                    cols="12" ds="4" md="3">
                        <post-row :item="post" />
                    </bxs-col>
                </bxs-row>

                <div
                v-else
                class="text-center">
                    <h4>No posts found</h4>
                </div>

                <div
                v-if="news.pagination && news.pagination.has_next_page"
                class="text-center mt-ui">
                    <c-btn
                    block
                    @click="$router.push({ query: { ...$route.query, page: news.pagination.next_page } })">Load more</c-btn>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
    name: 'news',
    async beforeRouteEnter (to, from, next) {
        await store.dispatch('getNews', { category: to.params.slug, page: to.query.page, limit: 20 })
        await store.dispatch('getCategories', { parent: to.params.slug })
        next()
    },
    computed: {
        ...mapState({
            page: state => state.page,
            news: state => state.news,
            categories: state => state.categories,
            is_in_transition: state => state.in_transition
        })
    },
    async beforeRouteUpdate (to, from, next) {
        next()
        await store.dispatch('getNews', { category: to.params.slug, page: to.query.page, limit: 20 })
        await store.dispatch('getCategories', { parent: to.params.slug })
        this.$store.commit('SET_APP_IN_TRANSITION', false)
    }
}
</script>