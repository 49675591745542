<template>
    <footer class="footer text-display text-white">
        <div>
            <bxs-layout>
                <buro-links />
            </bxs-layout>
        </div>

        <!--  -->
        <div class="footer--section-b">
            <bxs-layout>
                <div>
                    <ul>
                        <li
                        v-for="item in items"
                        :key="item.id"
                        class="mb-2 mb-0-last">
                            <router-link
                            :to="'/' + item.page.slug"
                            class="h4 mb-0">{{ item.title }}</router-link>
                        </li>
                    </ul>
                </div>

                <div class="mt-6 small text-mute">
                    <span>Suisse Consulences © {{ new Date().getFullYear() }}</span>
                    <span class="mx-1">-</span>
                    <router-link v-if="activeLang === 'ru'" to="/ru/privacy-policy">Privacy policy</router-link>
                    <router-link v-if="activeLang === 'en'" to="/privacy-policy">Privacy policy</router-link>
                    <span class="mx-1">-</span>
                    <router-link v-if="activeLang === 'ru'" to="/ru/cookie-policy">Cookie Policy</router-link>
                    <router-link v-if="activeLang === 'en'" to="/cookie-policy">Cookie Policy</router-link>
                </div>
            </bxs-layout>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'c-footer',
    data () {
        return {
            arrowSize: '1.5rem'
        }
    },
    computed: {
        ...mapState({
            items: state => state.menu
        }),
        activeLang() {
          return this.$store.state.language
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.footer {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    height: calc(100vh - var(--navbar-min-height));

    > div {
        flex: 1;
        height: 50%;
        overflow: hidden;

        &:first-child {
            background-color: var(--color-tertiary);
        }

        &:last-child {
            background-color: var(--color-primary);
        }

        > .layout {
            height: 100%;
            padding-top: var(--grid-gutter);
            padding-bottom: var(--grid-gutter);
        }
    }

    &--section-b {
        > .layout {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;

            @include mq(ml) {
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: flex-end;
            }
        }
    }
}
</style>
