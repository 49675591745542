<template>
    <button
    v-if="!to"
    :type="type"
    :disabled="disabled"
    :class="classes">
        <slot />
    </button>

    <router-link
    v-else
    :to="to"
    :disabled="disabled"
    :class="classes">
        <slot />
    </router-link>
</template>

<script>
export default {
    name: 'bxs-btn',
    props: {
        type: {
            type: String,
            required: false,
            default: 'button'
        },
        block: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: null
        },
        text: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['btn', {
                'btn-block': this.block,
                'btn-text': this.text,
                'btn-disabled': this.disabled
            }]
        }
    }
}
</script>

<style lang="scss" scoped>
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-primary);
    color: var(--color-background);
    min-width: 3rem;
    min-height: 3rem;
    padding: 0 2rem;
    border-radius: var(--radius);
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    margin: 0;
    line-height: normal;
    letter-spacing: normal;
    vertical-align: middle;

    transition: background-color var(--color-time-transition) ease-out, color var(--color-time-transition) ease-out;

    &.btn-block {
        flex: 1;
        display: flex;
        width: 100%;
    }

    &.btn-text {
        background: none;
        padding: 0;
        color: var(--color-typo);
    }

    @media (hover: hover) {
        &:hover {
            background-color: var(--color-tertiary);
            color: var(--color-background);

            &.btn-text {
                text-decoration: line-through;
                background: none;
                color: initial
            }
        }
    }
}
</style>