<template>
    <div
    :class="classes"
    :style="styles">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'layout',
    props: {
        fluid: {
            type: Boolean,
            required: false,
            default: false
        },
        'no-gutters': {
            type: Boolean,
            required: false,
            default: false
        },
        'max-width': {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        classes () {
            return ['layout', {
                'layout-fluid': this.fluid,
                'layout-no-gutters': this.noGutters
            }]
        },
        styles () {
            const v = {}
            if (this.maxWidth) v.maxWidth = this.maxWidth
            return v
        }
    }
}
</script>

<style lang="scss">
.layout {
    width: 100%;
    max-width: var(--boxed-ui);
    flex: 1 1 auto;

    margin-left: auto;
    margin-right: auto;

    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);

    &.layout-fluid {
        max-width: 100%;
    }

    &.layout-no-gutters {
        padding: 0 !important;

        .row {
            margin: 0 !important;

            .col {
                padding: 0 !important;
            }
        }
    }
}
</style>
