<template>
    <router-link
    :to="item.to"
    class="post-row">
        <div class="post-row--header">
            <bxs-figure
            :src="item.thumb_url"
            ratio="16/9"
            class="mb-2" />

            <div class="px-2">
                <p class="text-400 mb-0">{{ item.title }}</p>
            </div>
        </div>

        <div class="post-row--body flex nowrap align-end justify-between px-2">
            <div>
                <p class="mb-0 text-mute">
                    <small>{{ item.created_at }}</small>
                </p>

                <p
                v-if="item.categories.length > 0"
                class="mb-0">
                    <small
                    v-for="(cat, i) in item.categories"
                    :key="i">{{ cat.name }}<span v-if="i < item.categories.length - 1">, </span></small>
                </p>
            </div>

            <div>
                <c-arrow />
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'post-row',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/mq';

.post-row {
    position: relative;
    display: block;
    text-decoration: none !important;
    // min-height: 360px;
    // display: flex;
    // flex-flow: column nowrap;
    // justify-content: space-between;
    border-bottom: 1px solid rgba(25, 27, 29, 0.1);
    padding-bottom: 0.5rem;
    background-color: rgb(250, 250, 250);

    // @include mq (ds) {
    //     min-height: 440px;
    // }

    .figure {
        overflow: hidden;

        .figure--div {
            transition: transform 0.3s ease-out;
        }
    }

    @media (hover: hover) {
        &:hover {
            .figure--div {
                transform: scale(1.1);
            }
        }
    }

    &--body {
        aspect-ratio: 21 / 6;
    }
}
</style>