<template>
    <div class="buro-links">
        <div class="mb-ui">
            <p class="mb-0">Suisse Consulences Sagl</p>
            <a
            href="https://goo.gl/maps/wsM5NLKCfiGVaf2e9"
            target="_blank"
            class="mb-0">
                <span>Via Mastri Ligornettesi 24</span>
                <br>
                <span>CH - 6853 Ligornetto (Tessin)</span>
            </a>
        </div>

        <!--  -->
        <bxs-row :no-gutters="hideTo">
            <bxs-col
            cols="12"
            :ds="!hideTo ? 6 : 12"
            class="text-uppercase">
                <a
                href="mailto:info@suisseconsulences.ch"
                class="mr-6 mb-0 depress-link">
                    <small>Email</small>
                    <c-arrow size="0.5rem" dir="external" class="ml-2" />
                </a>

                <a
                href="https://goo.gl/maps/wsM5NLKCfiGVaf2e9"
                target="_blank"
                class="mr-6 mb-0 depress-link">
                    <small>Studio</small>
                    <c-arrow size="0.5rem" dir="external" class="ml-2" />
                </a>

                <a
                href="https://it.linkedin.com"
                target="_blank"
                class="mr-6 mb-0 depress-link">
                    <small>Linkedin</small>
                    <c-arrow size="0.5rem" dir="external" class="ml-2" />
                </a>

                <a
                href="https://www.instagram.com"
                target="_blank"
                class="mb-0 depress-link">
                    <small>Instagram</small>
                    <c-arrow size="0.5rem" dir="external" class="ml-2" />
                </a>
            </bxs-col>

            <bxs-col
            v-if="!hideTo"
            cols="12"
            ds="6"
            class="text-right-ds">
                <router-link
                to="/contacts"
                class="h5 mb-0"
                exact>Need help with a project? Get in touch</router-link>
            </bxs-col>
        </bxs-row>
    </div>
</template>

<script>
export default {
    name: 'buro-links',
    props: {
        'hide-to': {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>