import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import Home from '../views/Home.vue'
import Error404 from '../views/Error404.vue'

import About from '../views/About.vue'
import Contacts from '../views/Contacts.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import CookiePolicy from '../views/CookiePolicy.vue'

import Works from '../views/Works.vue'
import Work from '../views/Work.vue'
import Services from '../views/Services.vue'
import Service from '../views/Service.vue'
import Blog from '../views/Blog.vue'
import Post from '../views/Post.vue'

const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: '/errors/404'
    },
    {
        path: '/errors/404',
        name: 'error_404',
        component: Error404
    },
    // pages --------------------------------------------------------------
    {
        path: '/',
        name: 'home',
        component: Home,
        alias: ['/ru/'],
        meta: {
            is_page: true
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        alias: ['/ru/about'],
        meta: {
            is_page: true
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        alias: ['/ru/contacts'],
        component: Contacts,
        meta: {
            is_page: true
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy_policy',
        alias: ['/ru/privacy-policy'],
        component: PrivacyPolicy,
        meta: {
            is_page: true
        }
    },
    {
        path: '/cookie-policy',
        name: 'cookie_policy',
        alias: ['/ru/cookie-policy'],
        component: CookiePolicy,
        meta: {
            is_page: true
        }
    },
    {
        path: '/works',
        name: 'works',
        alias: ['/ru/works'],
        component: Works,
        meta: {
            is_page: true
        }
    },
    {
        path: '/services',
        name: 'services',
        alias: ['/ru/services'],
        component: Services,
        meta: {
            is_page: true
        }
    },
    {
        path: '/news/:slug?',
        name: 'blog',
        alias: ['/ru/news/:slug?'],
        component: Blog,
        meta: {
            is_page: true
        }
    },
    // single pages --------------------------------------------------------------
    {
        path: '/works/:slug',
        name: 'work',
        alias: ['/ru/works/:slug'],
        component: Work
    },
    {
        path: '/services/:slug',
        name: 'service',
        alias: ['/ru/services/:slug'],
        component: Service
    },
    {
        path: '/news/articles/:slug',
        name: 'article_post',
        alias: ['/ru/articles/:slug'],
        component: Post
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    console.log('> Router beforeEach()')

    // Checking language path
    if (to.path.startsWith('/ru')) {
      store.commit('set_language', 'ru')
    } else {
      store.commit('set_language', 'en')
    }

    store.commit('SET_APP_IN_TRANSITION', store.state.preloaded)
    store.commit('SET_APP_PAGE_ENTER', to.name)
    store.commit('SET_APP_PAGE_LEAVE', from.name)

    // if (to.name !== from.name) {
    //     if (to.meta.is_page) {
    //         console.log('fetch_page', to.path)
    //         await store.dispatch('getPage', to.path === '/' ? 'home' : to.path)
    //     }
    // }

    next()
})

export default router
