<template>
    <div class="about-view">
        <hero :title="page ? page.title : 'About'" />

        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <bxs-figure
                        v-if="page && page.highlighted_img"
                        :src="page.highlighted_img"
                        ratio="4/3" />

                        <bxs-figure
                        v-else
                        :src="null"
                        ratio="4/3" />
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <div
                        v-html="page && page.content"
                        class="wysiwyg"></div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <works-section />

        <services-section />
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'about',
    computed: {
        ...mapState({
            page: state => state.page
        })
    }
}
</script>
