<template>
    <section>
        <bxs-layout>
            <bxs-row>
                <bxs-col cols="12" ds="6">
                    <h3 v-if="!hideTitle">{{ data_page ? data_page.title : 'Services' }}</h3>

                    <div
                    v-if="data_page"
                    v-html="data_page.content"
                    class="wysiwyg"></div>
                </bxs-col>
            </bxs-row>

            <!-- <carousel
            :items="services.docs"
            centered>
                <template #item="{ item }">
                    <service-card :item="item" />
                </template>
            </carousel> -->

            <bxs-row>
                <bxs-col
                v-for="item in services.docs"
                :key="item.id"
                cols="12"
                ml="6"
                ds="4">
                    <card
                    :title="item.title"
                    :src="item.img_url"
                    :to="item.permalink" />
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'c-services',
    props: {
        'hide-title': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            data_page: null
        }
    },
    computed: {
      ...mapState({
          services(state) {
            const langServices = {
                en: state.services,
                ru: state.servicesRu
            }
            return langServices[this.activeLang] || state.services
          }
      }),
      activeLang() {
        return this.$store.state.language
      }
    },
    created () {
      if (this.activeLang === 'ru') {
        this.$wp.getPageRu('services', 'ru').then((doc) => {
              this.data_page = doc[0]
              this.$nextTick(() => this.$root.resize())
          })
      } else {
        this.$wp.getPage('services').then((doc) => {
            this.data_page = doc[0]
            this.$nextTick(() => this.$root.resize())
        })
      }
    }
}
</script>
