import axios from 'axios'
import qs from 'qs'

axios.defaults.timeout = 180000 // 3m

export default class HTTP {
    constructor(baseUrl) {
        this.base_url = baseUrl

        this.http = axios.create({
            baseURL: baseUrl || '',
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                    indices: false,
                    allowDots: false,
                    skipNulls: true,
                    format: 'RFC1738'
                })
            },
            validateStatus: status => status >= 200 && status < 400
        })

        return this
    }

    // publics

    get(resource, params) {
        return new Promise((resolve, reject) => {
            this.http.get(resource, { params: params })
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    post(resource, data, headers) {
        return new Promise((resolve, reject) => {
            this.http.post(resource, data, { headers: headers })
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    put(resource, data) {
        return new Promise((resolve, reject) => {
            this.http.put(resource, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    patch(resource, data) {
        return new Promise((resolve, reject) => {
            this.http.patch(resource, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    delete(resource, data) {
        return new Promise((resolve, reject) => {
            this.http.delete(resource, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    //

    // https://test.suisseconsulences.ch/wp-json/v1/menu/2
    getMenu(id = 2) {
        return this.get('v1/menu/' + id)
    }

    // https://test.suisseconsulences.ch/wp-json/v1/menu/footer
    getFooter() {
        return this.get('v1/menu/footer')
    }

    // https://test.suisseconsulences.ch/wp-json/wp/v2/pages?slug=home
    getPage(slug) {
        return this.get('wp/v2/pages', { slug })
    }

    getPageRu(slug, lang) {
        return this.get('wp/v2/pages?lang=ru', { slug })
    }

    // https://test.suisseconsulences.ch/wp-json/v1/slides
    getSlides() {
        return this.get('v1/slides')
    }

    // https://test.suisseconsulences.ch/wp-json/v1/categories?taxonomy=work_category
    getCategories(taxonomy, parent) {
        return this.get('v1/categories', { taxonomy, parent })
    }

    // https://test.suisseconsulences.ch/wp-json/v1/posts?type=service&limit=-1
    getPosts(q = {}) {
        return this.get('v1/posts', q)
    }

    getPost(slug, type) {
        return this.get('v1/posts/' + slug, {
            type
        })
    }

    submitForm (id, body) {
        // return new Promise((resolve, reject) => {
        //     return axios.post(`${this.base_url}/contact-form-7/v1/contact-forms/${id}/feedback`, body, {
        //         headers: {
        //           'Content-Type': 'multipart/form-data'
        //         }
        //     }).then(res => resolve(res.data)).catch(err => reject(err.response.data))
        // })

        return this.post(`/contact-form-7/v1/contact-forms/${id}/feedback`, body, {
            'Content-Type': 'multipart/form-data'
        })
    }
}
