<template>
    <div class="services-view">
        <hero :title="page ? page.title : 'Services'" />

        <services-section hide-title />

        <works-section />

        <!--
        <highlighted-articles-section />
        -->
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'services',
    computed: {
        ...mapState({
            page: state => state.page
        })
    }
}
</script>
